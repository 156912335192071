/* App.css */

body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
}

.app {
    display: flex;
}

.sidebar-div {
    background: #2c3e50;
    color: white;
    height: 100vh;
    position: fixed;
}

/* .sidebar a {
    text-decoration: none;
    color: white;
}


.header {
    background: #34495e;
    padding: 10px 20px;
    color: white;
}

.section {
    background: white;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
} */
