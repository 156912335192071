.post-image-section {
    margin: 12px;
    transition: margin-top 0.3s ease-in-out; /* Smooth transition for margin-top */
}

/* .post-image-area {
    margin-top: -110px;
} */

.hero-texts h1 {
    margin-top: 20px;
    font-family: 'Anek Malayalam', sans-serif;
    font-size: 40px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s;
}

.hero-text p {
    margin: 10px 0;
    font-size: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.author-info img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.author-info p {
    font-weight: 500;
}

.author-info .author-name {
    font-size: 20px;
}

.author-info .posted-date {
    font-size: 18px;
}

.author-info .posted-date span {
    font-weight: 600;
    opacity: 0.5;
}

.hero-texts .category {
    color: #fff;
    padding: 3px 10px 4px 10px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
    margin-bottom: 20px;
    background: linear-gradient(90deg, #404649b2, #404649b2);
    cursor: pointer;
    border: 1px solid linear-gradient(90deg, #404649b2, #404649b2);
    transition: 0.3s !important;
}

.hero-texts .category:hover {
    background: linear-gradient(90deg, #3b6ab2, #3b6ab2);
    transition: 0.3s !important;

}

.hero-texts .author-info p {
    font-weight: 600;
    font-size: 15px;
    transition: all 0.2s;
}

.hero-texts .author-info:hover p {
    color: #3b6ab2;
    transition: all 0.2s;
}

.hero-texts .author-info:hover img {
    opacity: 0.8;
    transition: all 0.2s;
}