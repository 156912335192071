/* Posts.css */

.section ul {
  list-style-type: none;
  padding: 0;
}

.section li {
  padding: 10px 0;
  /* border-bottom: 1px solid #ddd; */
}

.section li:last-child {
  border-bottom: none;
}