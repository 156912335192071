.blog-post {
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}

.blog-post img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    transition: all 0.5s;
    background: #00000029;
}

.post-info {
    padding: 10px 0px 20px 0px;
}

.post-info h3 {
    margin-top: 10px;
    font-family: 'Anek Malayalam', sans-serif;
    margin-bottom: 0;
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2s;
}

.post-info h3:hover {
    color: #3b6ab2;
    transition: all 0.2s;
}

.post-info p {
    margin: 0px 0;
    font-size: 16px;
    color: #555;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.post-info .post-date {
    font-size: 13px;
    color: #555;
    font-weight: 500;
}

.post-info .post-date span {
    font-weight: 600;
    opacity: 0.5;
}


.blog-post .category {
    color: #fff;
    padding: 1px 10px 1px 10px;
    border-radius: 25px;
    font-size: 15px;
    font-weight: 500;
    margin-right: 10px;
    margin-bottom: 20px;
    background: linear-gradient(90deg, #404649b2, #404649b2);
    cursor: pointer;
    border: 1px solid linear-gradient(90deg, #404649b2, #404649b2);
    transition: 0.3s !important;
}

.blog-post .category:hover {
    background: linear-gradient(90deg, #3b6ab2, #3b6ab2);
    transition: 0.3s !important;
}

.post-info .author-info p {
    font-weight: 600;
    font-size: 15px;
    transition: all 0.2s;
}

.post-info .author-info:hover p {
    color: #3b6ab2;
    transition: all 0.2s;
}

.post-info .author-info:hover img {
    opacity: 0.8;
    transition: all 0.2s;
}