.carousel-caption {
  position: absolute;
  bottom: 20px;
  left: 20px !important;
  right: 20px !important;
  color: #fff;
  border-radius: 5px;
}

.hero-text h3 {
  margin-top: 20px;
  font-family: 'Anek Malayalam', sans-serif;
  font-size: 40px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  text-overflow: ellipsis;
  transition: all 0.3s;
}

.hero-text h3:hover {
  opacity: 0.9;
  transition: all 0.3s;
}

.hero-text p {
  margin: 10px 0;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.author-info img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.author-info .author p {
  font-weight: 500;
  font-size: 15px;
}

.author-info .author-name {
  font-size: 20px;
}

.author-info .posted-date {
  font-size: 18px;
}

.author-info .posted-date span {
  font-weight: 600;
  opacity: 0.5;
}

.hero-title .category {
  color: #fff;
  padding: 2px 10px 3px 10px;
  border-radius: 25px;
  font-size: 15px ;
  font-weight: 500;
  margin-right: 10px;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #404649b2, #404649b2);
  cursor: pointer;
  border: 1px solid linear-gradient(90deg, #404649b2, #404649b2);
  transition: 0.3s !important;
}

.hero-title .category:hover {
  background: linear-gradient(90deg, #3b6ab2, #3b6ab2);
  transition: 0.3s !important;

}

.carousel-indicators [data-bs-target] {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 10px !important;
  height: 10px !important;
  margin: 0 5px;
  left: 0 !important;
  right: 0 !important;
}

.carousel-indicators {
  /* margin-left: 40px !important; */
  justify-content: flex-center !important;
}

.carousel-item {
  height: 700px;
}

.carousel-item img,
.carousel-item .loading-div {
  height: 700px;
  object-fit: cover;
}

.image-placeholder.loaded {
  background: none;
  opacity: 1;
}