span {
    font-weight: 600;
    font-size: 14px;
}

.section-header__label span{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
}

.section-header__actions Button{
    margin-right: 10px;
    padding: 7px 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
}

ul .list-item h5{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.3s;
}

ul .list-item h5:hover{
    color: #ff0000;
}

ul .list-item .fa-ellipsis {
    font-size: 20px;
    color: #000;
    cursor: pointer;
    transition: 0.3s;
}

ul .list-item .fa-ellipsis:hover {
    color: #ff0000;
}

ul .list-item .dropdown-menu i {
    font-size: 14px;
}

.rotate {
    transform: rotate(90deg);
    transition: transform 0.2s ease-in-out;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link:hover {
    color: #ffff !important;
    background-color: #3498db !important;
    font-weight: 500;
    font-size: 16px;
    border: 1px solid #dee2e6;
    border-radius: 0;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    transition: 0.3s;
}

.nav-tabs .nav-link{
    color: #000;
    font-weight: 500;
    font-size: 16px;
    border: none;
    border-radius: 0;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    transition: 0.3s;
}
