body {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
    font-family: "Baloo Chettan 2", sans-serif !important;
    overflow-x: hidden;
    
}
