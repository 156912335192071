.main-content {
  margin: 12px;
  margin-top: -12px;
  
}

/* .main-div { */
  /* margin-top: -110px; */
/* } */

.hero {
  position: relative;
  width: 100%;
  background-color: #fff;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
}

