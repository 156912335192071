.social-section {
    margin: 12px;
}

.explore-sakshi .bg-image,
.article-count .bg-image {
    background-size: cover !important;
}

.title {
    font-size: 35px;
    color: #3b6ab2;
    font-family: 'Monoton', sans-serif;
}