/* Sidebar.css */

.sidebar {
    padding: 20px;
  }
  
  .sidebar h2 {
    margin-top: 0;
  }
  
  .sidebar .MuiList-root {
    margin-top: 20px;
  }
  
  .sidebar .MuiListItemText-primary {
    color: white;
  }
  