.footer {
    background-color: #3b6ab2;
    border-radius: 10px;
    color: #fff;
    padding: 10px 10px;
    margin: 12px;
    margin-top: 10px;
  }

  .footer-content {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
  }
  
  .footer-content div {
    flex: 1;
    margin: 0 20px;
  }
  
  .footer-content h4 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .footer-content a {
    display: block;
    color: #e3e3e3;
    text-decoration: none;
    margin-bottom: 10px;
  }

  .newsletter {
    margin: 0;
  }
  
  .newsletter .mail-box {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 5px; 
  }

  .newsletter .mail-box input,
  .newsletter .mail-box input:active,
  .newsletter .mail-box input:focus,
  .newsletter .mail-box input:hover {
    width: 100%;
    border: none;
    border-radius: 5px;
    background: #fff;
  }

  .hstack div {
    margin: 0 5px;
  }
  
  .newsletter button {
    padding: 5px 15px;
    border: none;
    background-color: #3b6ab2;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }
  
  .footer-bottom span {
    font-size: 14px;
  }
  
  .footer-bottom .ddb a {
    color: #fff;
    font-weight: 600;
    text-decoration: none;
  }
  