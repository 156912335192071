.blog-section {
  margin: 20px;
}

.blog-section .cat-title {
  font-size: 35px;
  color: #3b6ab2;
  font-family: 'Monoton', sans-serif;
}

/* .blog-section .blog-posts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
} */