.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: linear-gradient(180deg, #00000091, #f8f9fa00);
  color: #fff;
  transition: all 0.3s ease;
}

.header .nav-brand {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  font-size: xx-large;
  transform: all 0.5s ease !important;
}

.header.active {
  color: #3b6ab2;
  background: linear-gradient(180deg, #f8f9fa, #f8f9fa);
}

.header.active .nav-brand,
.header.active .navbar-nav .nav-link {
  color: #3b6ab2;
  font-weight: 700;
  transform: all 0.5s ease !important;
}

.logo {
  height: 55px; /* Adjust as needed */
  width: 145px;
  transition: all 0.3s ease;
}

.header.active .logo {
  height: 50px; /* Adjust as needed */
  width: 155px;
  transition: all 0.3s ease;
}

.navbar-nav .nav-link {
  color: #fff;
  font-weight: 700;
  transform: all 0.5s;

}

.navbar-nav .nav-link:hover {
  color: #3b6ab2;
}

.navbar-nav .nav-link:focus {
  color: #3b6ab2;
}

.search-box {
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #f9f8fa85, #f8f9fa98);
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 20px;
  border: 1px solid #ffffff ;
}

.search-box .fa-magnifying-glass {
  color: #ffffff;
  rotate: 0deg;
}

.header.active .search-box {
  background: linear-gradient(90deg, #f8f9fa, #f8f9fa);
  border: 1px solid #000000;
}

.header.active .search-box input,
.header.active .search-box input::placeholder{
  color: #000;
  caret-color: #000000;
}


.header.active .search-box .fa-magnifying-glass {
  color: #000;
}

.search-box input {
  border: none;
  outline: none;
  padding: 5px;
  margin-left: 5px;
  background-color: #00000000;
  caret-color: #ffffff;

}

.search-box input::placeholder {
  color: #ffffff;
}






