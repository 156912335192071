.post-content {
  border-radius: 10px;
  margin-top: 20px;
}

.post-content .article-content {
  padding: 20px 200px;
  font-weight: 500 ;
  font-size: 18px;
  line-height: 1.8;
  white-space: pre-wrap;
  text-align: justify;
}

.post-content .article-content p {
  display: grid;
}

.post-content .article-content img,
.post-content .article-content svg{
  width: 70%;
  height: auto;
  border-radius: 10px;
  margin:20px auto;
  background-color: #3b6ab2;
}

#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  /* display: none; */
  background-color: #ffffff;
  color: #3b6ab2;
  border-radius: 50%;
  border: 2px solid #3b6ab2;
  transition: 0.3s ease;

}

#btn-back-to-top:hover,
#btn-back-to-top:focus {
  background-color: #3b6ab2;
  color: #ffffff;
}